const ja = {
  message: {
    waiting: "只今処理中、しばらくお待ちください。",
    enter_input_tip: "必要な情報を入力してください",
    login_error_input_tip: "ログインエラー、入力した情報をチェックしてください",
    email_address_input_tip: "メールアドレスは空欄にすることができません",
    send_code_success: "認証コード発行済み",
    wallet_balance: "ウオレット残高",
    explain_text:
      "※ERC-20　USDTとなります。送金する際に、正しくメインネットをお選びください。",
    explain_text_two: "送金手数料はご負担下さい",
    successful: "成功",
  },
  login: {
    title: "こんにちは、おかえりなさい",
    subtitle: "ログインしてください",
    email: "メールアドレス",
    email_code: "ワンタイムパスワード",
    send_code_btn: "ワンタイムパスワード発行",
    btn_login: "ログイン",
    go_to_register: "新規登録へ",
  },
  register: {
    title: "新規登録",
    subtitle: "無料サービスを開始",
    email: "メールアドレス",
    email_code: "認証コード",
    send_code_btn: "認証コード発行",
    first_name: "姓",
    last_name: "名",
    invitation_code: "招待コード",
    btn_register: "登録",
    go_to_login: "登録へ",
    registration_success: "登録済み",
  },
  goods: {
    btn_to_buy: "購入",
    shopping_address_title_tip:
      "NFT受取用BSCチェーンアドレスを入力してください",
    platform_account_recharge: "アカウントのチャージ",
    platform_account_recharge_tip:
      "購入残高が不足しています。ウォレットアドレスにチャージしてください",
    recharge_address: "USDT入金宛先アドレス（ERC20）",
    successful_purchase_tip: "お申し込み頂き、誠にありがとうございます。",
    successful_purchase_tip_two:
      "ご記載していただいたBSCアドレスの方にNFTを送付いたします。",
    failed_purchase_tip:
      "商品の購入に失敗しました。しばらくしてからもう一度お試しください",
    wrong_address_tip: "BSCチェーンアドレスエラー",
  },
  input: {
    text_input_tip: "ここに入力してください",
    text_input_money_tip: "ここにチャージ金額を入力してください",
  },
  btn: {
    cancel: "キャンセル",
    sure: "確定",
  },
  copy: {
    copy_success: "コピー成功",
    copy_error: "コピー失敗",
    not_support: "このブラウザはサポートされていません",
  },
};

export default ja;
