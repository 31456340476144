import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { createRouterGuards } from "../permission";
import { App } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/redirect/:path(.*)",
    name: "redirect",
    component: () => import("@/views/Redirect/index.vue"),
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/Login.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/Register.vue"),
  // },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: () => import("../views/Test.vue"),
  // },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/ErrPage/notFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: "/404",
  },
];

// const asyncRouterMap: Array<RouteRecordRaw> = [
//   {
//     path: "/:pathMatch(.*)*",
//     name: "NotFound",
//     redirect: "/404",
//   },
// ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export function setupRouter(app: App) {
  createRouterGuards(router, routes);
  app.use(router);
}
export default router;
