import { createStore, createLogger } from "vuex";
// import createPersistedState from 'vuex-persistedstate';
import { store as user, UserStore, UserState } from "@/store/modules/user";

export interface RootState {
  user: UserState;
}

export type Store = UserStore<Pick<RootState, "user">>;

const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];
// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    user,
  },
});

export function useStore(): Store {
  return store as Store;
}
