import { createApp } from "vue";
import router, { setupRouter } from "./router";
import { store } from "./store";
import i18n from "./plugin/i18n";
import axios from "axios";
import App from "./App.vue";
import BalmUI from "balm-ui";
import BalmUIPlus from "balm-ui/dist/balm-ui-plus";
import BalmUINext from "balm-ui/dist/balm-ui-next";
import "@/common/common";
import "./permission.ts";
import "balm-ui-css";
const app = createApp(App);
app.config.globalProperties.$http = axios;

app.use(BalmUI);
app.use(BalmUIPlus);
app.use(BalmUINext);
app.use(i18n);
app.use(store);
app.use(router);
setupRouter(app);
// 路由准备就绪后挂载APP实例
router.isReady().then(() => app.mount("#app"));
