import messages from "@/language/index";
import { createI18n } from "vue-i18n";

// 获取用户浏览器优先选择语言
/**
 * language：返回当前的浏览器语言（来自 Mozilla Developer Center）
 * userLanguage：返回操作系统设定的自然语言（来自 MSDN）
 * browserLanguage：返回当前的浏览器语言（来自 MSDN）
 * systemLanguage：返回当前操作系统的缺省语言（来自 MSDN）支持IE5+
 * */
// function getLanguage() {
//   console.log('本地系统语言', navigator.language, navigator.languages)
//   try {
//     // @ts-ignore
//     return (navigator.language || navigator.userLanguage || navigator.systemLanguage).toLowerCase()
//   } catch (e) {
//   }
//   return 'ja'
// }

//判断用户使用的语言
// let loc = getLanguage().substr(0, 2) === "zh" ? "zh" :
//   getLanguage().substr(0, 2) === "en" ? "en" : "ja"

const i18n = createI18n({
  locale: "en",
  globalInjection: true,
  legacy: false,
  messages,
});

export default i18n;
