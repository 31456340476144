import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { UserState } from "./state";

export type Getters = {
  token(state: UserState): string;
  balance(state: UserState): string | number;
  isConnectWallet(state: UserState): boolean;
  walletAddress(state: UserState): string;
  rechargeAddress(state: UserState): string;
};

export const getters: GetterTree<UserState, RootState> & Getters = {
  token: (state) => state.token,
  balance: (state) => state.balance,
  isConnectWallet: (state) => state.isConnectWallet,
  walletAddress: (state) => state.walletAddress,
  rechargeAddress: (state) => state.rechargeAddress,
};
