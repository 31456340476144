import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css";
import { store } from "./store";
import { Router } from "vue-router";
// import moment from 'moment';
// const whiteList = ["/login", "/register"];

export function createRouterGuards(router: Router, asyncRouterMap: any) {
  router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
    // if (store.getters["user/token"]) {
    //   if (String(firstLoginTime) === "false") {
    //     console.log("首次登录");
    //     // router.addRoute(asyncRouterMap);
    //     firstLoginTime = "true";
    //     localStorage.setItem("IS_FIRST_LOGIN", "true");
    //     // console.log('to.matched.length', to.matched.length)
    //     next({ ...to, replace: true });
    //   } else {
    //     if (to.path === "/login") {
    //       next("/");
    //     } else {
    //       next();
    //     }
    //   next();
    //   }
    // } else {
    //   if (whiteList.includes(to.path)) {
    //     next();
    //   } else {
    //     next('/login');
    //   }
    // }
  });

  router.afterEach(() => {
    NProgress.done();
  });
}
