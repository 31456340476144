import { MutationTree } from "vuex";
import { UserState } from "./state";
import { UserMutationTypes } from "./mutation-types";
import { ProductInfo } from "@/store/modules/user/state";
export type Mutations<S = UserState> = {
  [UserMutationTypes.SET_TOKEN](state: S, token: string): void;
  [UserMutationTypes.SET_BALANCE](state: S, data: string | number): void;
  [UserMutationTypes.SET_IS_CONNECT_WALLET](state: S, data: boolean): void;
  [UserMutationTypes.SET_CONNECT_WALLET_ADDRESS](state: S, data: string): void;
  [UserMutationTypes.SET_RECHARGE_ADDRESS](state: S, data: string): void;
  [UserMutationTypes.SET_PRODUCT_INFO](state: S, data: ProductInfo): void;
  [UserMutationTypes.SET_IS_SHOW_PRODUCT_INFO](state: S, data: boolean): void;
  [UserMutationTypes.SET_IS_SHOW_PAGE_ERROR](state: S, data: boolean): void;
};

export const mutations: MutationTree<UserState> & Mutations = {
  [UserMutationTypes.SET_TOKEN](state: UserState, token: string) {
    state.token = token;
  },
  [UserMutationTypes.SET_BALANCE](state: UserState, data: string | number) {
    state.balance = data;
  },
  [UserMutationTypes.SET_RECHARGE_ADDRESS](state: UserState, data: string) {
    state.rechargeAddress = data;
  },
  [UserMutationTypes.SET_IS_CONNECT_WALLET](state: UserState, data: boolean) {
    state.isConnectWallet = data;
  },
  [UserMutationTypes.SET_CONNECT_WALLET_ADDRESS](
    state: UserState,
    data: string
  ) {
    state.walletAddress = data;
  },
  [UserMutationTypes.SET_PRODUCT_INFO](state: UserState, data: ProductInfo) {
    state.product = Object.assign({}, data);
  },
  [UserMutationTypes.SET_IS_SHOW_PRODUCT_INFO](
    state: UserState,
    data: boolean
  ) {
    state.isShowProduct = data;
  },
  [UserMutationTypes.SET_IS_SHOW_PAGE_ERROR](state: UserState, data: boolean) {
    state.isShowPageError = data;
  },
};
