import { ActionTree, ActionContext } from "vuex";
import { RootState, store, useStore } from "@/store";
import { state, UserState } from "./state";
import { Mutations } from "./mutations";
import { UserMutationTypes } from "./mutation-types";
import { UserActionTypes } from "./action-types";
import { get_license, login } from "@/api/user";
import { connect_meta_mask } from "@/utils/connect";
import { clearAllCookie } from "@/utils/index";
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<UserState, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    data: { login: string; register: number; sign: string }
  ): void;
  [UserActionTypes.GET_LICENSE]({ commit }: AugmentedActionContext): void;
  // [UserActionTypes.LOGIN_BY_EMAIL](
  //   { commit }: AugmentedActionContext,
  //   data: { code: string; email: string }
  // ): void;
  // [UserActionTypes.BEGIN_CONNECT_META_MASK]({
  //   commit,
  // }: AugmentedActionContext): void;
  // [UserActionTypes.GET_USER_INFO]({
  //   commit
  // }: AugmentedActionContext): void;
  // [UserActionTypes.LOGIN_OUT](): void;
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    data: { login: string; register: number; sign: string }
  ) {
    await login(data)
      .then(async (response: any) => {
        if (response.message) {
          sessionStorage.setItem("USER_TOKEN", response.message);
          commit(UserMutationTypes.SET_TOKEN, response.message);
          return Promise.resolve()
        } else {
          return Promise.reject(response)
        }
      })
      .catch((err) => {
        return Promise.reject(err)
      });
  },
  async [UserActionTypes.GET_LICENSE]({ commit }: AugmentedActionContext) {
    await get_license({})
      .then(async (response: any) => {
        if (response.data) {
          commit(UserMutationTypes.SET_PRODUCT_INFO, response.data);
          commit(UserMutationTypes.SET_IS_SHOW_PRODUCT_INFO, true);
          return Promise.resolve()
        } else {
          return Promise.reject(response)
        }
      })
      .catch((err) => {
        return Promise.reject(err)
      });
  },

  //
  // async [UserActionTypes.LOGIN_BY_EMAIL](
  //   { commit }: AugmentedActionContext,
  //   data: { code: string; email: string }
  // ) {
  //   await login_by_email_code(data)
  //     .then(async (response) => {
  //       localStorage.setItem("USER_TOKEN", data.code);
  //       commit(UserMutationTypes.SET_TOKEN, data.code);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // },
  //
  // async [UserActionTypes.BEGIN_CONNECT_META_MASK]({
  //   commit,
  // }: AugmentedActionContext) {
  //   console.log("开始准备连接web3");
  //   await connect_meta_mask().then(async (res: any) => {
  //     console.log("res", res);
  //     if (res && res.length > 0) {
  //       commit(UserMutationTypes.SET_IS_CONNECT_WALLET, true);
  //       commit(UserMutationTypes.SET_CONNECT_WALLET_ADDRESS, res);
  //     }
  //   });
  // },
  //
  // async [UserActionTypes.GET_USER_INFO](
  //     { commit }: AugmentedActionContext
  // ) {
  //   await get_user_info({})
  //       .then(async (res) => {
  //         console.log("res 用户信息", res);
  //         commit(UserMutationTypes.SET_BALANCE, res?.balance ?? 0);
  //         commit(UserMutationTypes.SET_RECHARGE_ADDRESS, '0xDA10561f774Ab6B721DD098Bc5d83CAA4F3CECe3' || (res?.address ?? ''));
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // },
  //
  // [UserActionTypes.LOGIN_OUT]() {
  //   return new Promise((resolve, reject) => {
  //     localStorage.clear();
  //     clearAllCookie();
  //     resolve('');
  //   })
  // },
};
