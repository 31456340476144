import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAlert } from 'balm-ui';
const $alert = useAlert();
import { store } from "@/store";

declare module "axios" {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
    (response: AxiosResponse): Promise<any>;
  }
}

// 创建axios实例
const protocol = window.location.protocol || 'https:';
const service = axios.create({
  baseURL: protocol + '//' + process.env.VUE_APP_BASE_URL,
  timeout: 10000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (store.getters["user/token"].length > 0) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = "Bearer " + store.getters["user/token"];
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response拦截器
service.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    console.log("response：", response.status, response);
    // 200～299
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return Promise.reject(response.data);
    }
  },
  (error) => {
    if (!window.navigator.onLine) {
      $alert("There was a problem with the network, please try again later");
    } else {
      if (error.response?.data?.message) {
        $alert(error.response.data.message);
      }
    }
    return Promise.reject(
      error?.response?.data ?? {
        message: "There was a problem with the network, please try again later",
      }
    );
  }
);

export default service;
