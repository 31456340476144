export interface ProductInfo {
  Type: number;
  Value: number;
  Mint: number; // 0: can withdraw 1: can not withdraw
  MintTime: number;
  Image: string;
}

export interface UserState {
  token: string;
  balance: string | number;
  isConnectWallet: boolean;
  walletAddress: string;
  balanceDecimal: number;
  rechargeAddress: string;
  product: ProductInfo;
  isShowProduct: boolean;
  isShowPageError: boolean;
}

export const state: UserState = {
  token: sessionStorage.getItem("USER_TOKEN") || "",
  balance: 0,
  isConnectWallet: false,
  walletAddress: "",
  balanceDecimal: 4,
  rechargeAddress: "",
  product: {
    Type: 0,
    Value: 0,
    Mint: 1,
    MintTime: 0,
    Image: "",
  },
  isShowProduct: false,
  isShowPageError: false,
};
