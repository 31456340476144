export enum UserMutationTypes {
  SET_TOKEN = "SET_TOKEN",
  SET_IS_CONNECT_WALLET = "SET_IS_CONNECT_WALLET",
  SET_CONNECT_WALLET_ADDRESS = "SET_CONNECT_WALLET_ADDRESS",
  SET_BALANCE = "SET_BALANCE",
  SET_RECHARGE_ADDRESS = "SET_RECHARGE_ADDRESS",
  SET_PRODUCT_INFO = "SET_PRODUCT_INFO",
  SET_IS_SHOW_PRODUCT_INFO = "SET_IS_SHOW_PRODUCT_INFO",
  SET_IS_SHOW_PAGE_ERROR = "SET_IS_SHOW_PAGE_ERROR",
}
