const en = {
  message: {
    waiting: "Processing, please wait a moment",
    enter_input_tip: "Please enter the required information",
    login_error_input_tip:
      "Login error, please check the information you entered",
    email_address_input_tip: "Email address cannot be empty",
    send_code_success: "Verification code has been sent",
    wallet_balance: "Wallet balance",
    explain_text:
      "※ERC-20 USDT. Please select the correct mainnet when sending money",
    explain_text_two: "Please bear the remittance fee",
    successful: "successful",
  },
  login: {
    title: "Hi, Welcome Back",
    subtitle: "Enter your credentials to continue",
    email: "Email address",
    email_code: "Verification code",
    send_code_btn: "Send verification code",
    btn_login: "Login",
    go_to_register: "Sign up",
  },
  register: {
    title: "Register",
    subtitle: "Free tour coming soon",
    email: "Email address",
    email_code: "Verification code",
    send_code_btn: "Send code",
    first_name: "First name",
    last_name: "Last name",
    invitation_code: "Invitation code",
    btn_register: "Register",
    go_to_login: "Sign in",
    registration_success: "Registration success",
  },
  goods: {
    btn_to_buy: "Buy",
    shopping_address_title_tip:
      "Please enter the BSC chain address for receiving NFT",
    platform_account_recharge: "Platform account recharge",
    platform_account_recharge_tip:
      "The purchase balance is insufficient. Please recharge to your wallet address",
    recharge_address: "USDT deposit destination address (ERC20)",
    successful_purchase_tip:
      "Thank you for your purchase. We will send NFT to the address you provided.",
    successful_purchase_tip_two:
      "Please note that the NFT will be sent to the address you provided after the transaction is confirmed.",
    failed_purchase_tip: "Purchase failed",
    wrong_address_tip: "BSC chain address error",
  },
  input: {
    text_input_tip: "Please enter the required information",
    text_input_money_tip: "Please enter the correct amount",
  },
  btn: {
    cancel: "Cancel",
    sure: "Sure",
  },
  copy: {
    copy_success: "Copy success",
    copy_error: "Copy error",
    not_support: "Not support copy",
  },
};

export default en;
