import request from "@/api/request";

// 登录
export function login(data: any) {
  return request({
    url: "/v1/login",
    method: "post",
    data,
  });
}

// 获取nft
export function get_license(data: any) {
  return request({
    url: "/v1/getLicense",
    method: "get",
    params: data,
  });
}
// withdraw
export function withdraw(data: any) {
  return request({
    url: "/v1/withdraw",
    method: "post",
    data,
  });
}

// 发送登录邮箱验证码
export function send_login_email_code(data: any) {
  return request({
    url: "/v1/login/send_login_email_code",
    method: "post",
    data,
  });
}

// 开始登录
export function login_by_email_code(data: any) {
  return request({
    url: "/v1/login/login_by_email_code",
    method: "post",
    data,
  });
}

// 发送注册邮箱验证码
export function send_register_code(data: any) {
  return request({
    url: "/v1/register/send_register_code",
    method: "post",
    data,
  });
}

// 开始注册
export function confirm_register_code(data: any) {
  return request({
    url: "/v1/register/confirm_register_code",
    method: "post",
    data,
  });
}

// 获取用户信息
export function get_user_info(data: any) {
  return request({
    url: "/v1/user/info",
    method: "get",
    params: data,
  });
}

// 获取余额
export function get_user_balance(data: any) {
  return request({
    url: "/v1/user/balance",
    method: "get",
    params: data,
  });
}

// 平台账户充值
export function deposit(data: any) {
  return request({
    url: "/v1/deposit",
    method: "post",
    data,
  });
}

// 获取商品列表
export function get_goods_list(data: any) {
  return request({
    url: "/v1/get_goods_list",
    method: "get",
    params: data,
  });
}

// 购买商品
export function buy_goods(data: any) {
  return request({
    url: "/v1/buy",
    method: "post",
    data,
  });
}
